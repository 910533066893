<template>
  <div class="ra-box">
    <div
      class="accordion"
      role="tablist"
    >
      <b-card no-body>
        <b-card-header
          header-tag="header"
          role="tab"
        >
          <b-button
            v-b-toggle.from-book
            block
            data-testid="relatedArticleFromTheBookHead"
          >
            From the Book
          </b-button>
        </b-card-header>

        <b-collapse
          id="from-book"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <ul>
              <li
                v-for="(item, index) in relatedArticleListState.fromBook"
                :key="`fromBook#${index}`"
                data-testid="relatedArticleFromTheBookList"
              >
                <router-link :to="`/article/${item.categoryList.join('/')}/${item.slug}-${item.articleId}`">
                  {{ item.title }}
                </router-link>
              </li>

              <li>
                <router-link :to="`/book/${relatedArticleListState.relatedBook.categoryList.join('/')}/${relatedArticleListState.relatedBook.slug}-${relatedArticleListState.relatedBook.bookId}`">
                  View All Articles From Book
                </router-link>
              </li>
            </ul>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body>
        <b-card-header
          header-tag="header"
          role="tab"
        >
          <b-button
            v-b-toggle.rel-article
            block
            data-testid="relatedArticleRelatedHead"
          >
            Related Articles
          </b-button>
        </b-card-header>

        <b-collapse
          id="rel-article"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <ul>
              <li
                v-for="(item, index) in relatedArticleListState.fromCategory"
                :key="`fromCategory#${index}`"
                data-testid="relatedArticleRelatedList"
              >
                <router-link :to="`/article/${item.categoryList.join('/')}/${item.slug}-${item.articleId}`">
                  {{ item.title }}
                </router-link>
              </li>
            </ul>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body>
        <b-card-header
          header-tag="header"
          role="tab"
        >
          <b-button
            v-b-toggle.trending
            block
            data-testid="relatedArticleTrendingHead"
          >
            Trending
          </b-button>
        </b-card-header>

        <b-collapse
          id="trending"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <ul>
              <li
                v-for="(item, index) in relatedArticleListState.trendingArticles"
                :key="`trendingArticle#${index}`"
                data-testid="relatedArticleTrendingList"
              >
                <router-link :to="`/article/${item.categoryList.join('/')}/${item.slug}-${item.articleId}`">
                  {{ item.title }}
                </router-link>
              </li>
            </ul>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { relatedArticleListState } from '../../store/article/selector'
import { BCard, BCardHeader, BButton, BCollapse, BCardBody, VBToggle } from 'bootstrap-vue'

export default {
  name: 'RelatedArticleList',
  components: {
    BCard,
    BCardHeader,
    BButton,
    BCollapse,
    BCardBody
  },
  directives: {
    'b-toggle': VBToggle
  },
  computed: {
    ...mapState({
      relatedArticleListState: state => relatedArticleListState(state)
    })
  }
}
</script>

<style lang="scss" scoped>
.ra-box {
  margin-bottom: 32px;
  max-width: 300px;

  .card {
    border: none;
    border-radius: 0;

    &:last-child .card-header {
      margin-bottom: 0;
    }

    .card-header .btn {
      font-size: 1rem;
      line-height: 20px;
      padding: 32px 24px;
      color: $black;
      display: block;
      font-weight: bold;
      background: #f2f2f2;
      border: none;
      border-radius: 0;
      box-shadow: none;
      text-align: left;
      transition: none;

      &:hover,
      &:active {
        background: #dbdbdb;
        border: none;
        color: inherit;
      }
    }

    .card-body {
      padding: 0;

      ul {
        border: none;
      }

      li {
        margin-bottom: 0;

        a {
          display: block;
          padding: 32px 24px;
          color: $black;
          font-size: 0.875rem;
          line-height: 24px;
          border-bottom: 1px solid $grey-4;
        }
      }
    }
  }

  .accordion {
    border: 1px solid $grey-4;

    & > .card {
      & > .card-header {
        padding: 0;
        border-radius: 0;
        border: none;
        margin-bottom: 4px;
      }

      &:last-child > .card-header {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-md-max) {
    width: 100%;
    max-width: 100%;

    .card {
      .card-header .btn,
      .card-body li a {
        font-size: 0.875rem;
        padding: 32px 16px;
      }
    }
  }
}
</style>
