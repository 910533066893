<template>
  <div class="book-purchase-cta">
    <h5 data-testid="bookInfoTitle">
      {{ bookInfoState.title }}
    </h5>

    <div class="book-author-image">
      <img
        :src="bookInfoState.imageUrl"
        data-testid="bookInfoImage"
      >
    </div>

    <custom-button
      secondary
      label="Explore Book"
      class="mb-3"
      :to="bookInfoState.url"
      data-testid="bookInfoExploreButton"
    />
    <custom-button
      primary
      label="Buy On Amazon"
      :href="bookInfoState.amazonDefaultUrl"
      data-testid="bookInfoBuyButton"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bookInfoState } from '../../store/article/selector'
import CustomButton from '../../components/atoms/CustomButton'

export default {
  name: 'BookInfo',
  components: {
    CustomButton
  },

  computed: {
    ...mapState({
      bookInfoState: state => bookInfoState(state)
    })
  }
}
</script>

<style lang="scss" scoped>
.book-purchase-cta {
  display: flex;
  border: 1px solid #dbdbdb;
  max-width: 300px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;

  h5 {
    margin: 0 0 32px 0;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    text-transform: inherit;
  }

  .book-author-image {
    background: $grey-7;
    margin-bottom: 32px;

    img {
      height: 175px;
    }
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .book-purchase-cta {
    max-width: 100%;
    width: 100%;
    padding: 32px;
    margin: 0 auto;

    h5 {
      font-size: 0.875rem;
      margin-bottom: 24px;
    }

    .book-author-image {
      margin-bottom: 24px;
    }
  }
}
</style>
