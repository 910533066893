<template>
  <article class="article-page">
    <spinner
      v-if="!articleTemplateState.articleLoadedStatus || articleTemplateState.articleLoadedStatus === ApiStatus.progress"
      has-overlay
    />

    <b-container
      v-else
      class="custom-container"
    >
      <section id="tab0">
        <article-introduction />
      </section>

      <b-row class="article-body">
        <b-col
          lg="9"
          md="12"
        >
          <div class="page-content d-flex">
            <spy-navigation :items="articleTemplateState.inThisArticle" />

            <div class="middle-content">
              <div
                id="inner-middle-content"
                ref="content"
                class="inner-middle-content"
              >
                <article
                  class="article-content"
                  data-testid="articleContent"
                  v-html="articleTemplateState.content"
                />

                <about-author />

                <article-included-in />
              </div>
            </div>
          </div>
        </b-col>

        <b-col
          lg="3"
          md="12"
          class="right-sidebar"
        >
          <related-article-list />

          <book-info />
        </b-col>
      </b-row>
    </b-container>
  </article>
</template>

<script>
import { mapState } from 'vuex'
import { articleTemplateState } from '../../store/article/selector'
import { ApiStatus } from '../../api/constants/ApiConstants'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import ArticleIntroduction from '../organisms/ArticleIntroduction'
import ArticleIncludedIn from '../organisms/ArticleIncludedIn'
import RelatedArticleList from '../organisms/RelatedArticleList'
import BookInfo from '../organisms/BookInfo'
import AboutAuthor from '../organisms/AboutAuthor'
import SpyNavigation from '../organisms/SpyNavigation'
import Spinner from '../atoms/Spinner'

export default {
  name: 'ArticleTemplate',
  components: {
    RelatedArticleList,
    BookInfo,
    AboutAuthor,
    SpyNavigation,
    ArticleIntroduction,
    ArticleIncludedIn,
    Spinner,
    BContainer,
    BRow,
    BCol
  },
  computed: {
    ...mapState({
      articleTemplateState: state => articleTemplateState(state)
    }),
    ApiStatus: function () {
      return ApiStatus
    }
  }
}

</script>

<style lang="scss" scoped>
.article-page {
  padding: 38px 0;

  .page-content {
    border-top: 1px solid $grey-4;

    .sticky-sidebar {
      flex: 1;
    }

    .middle-content {
      padding-left: 40px;
      flex: 1;
      padding-top: 0;

      .inner-middle-content {
        margin-top: 108px;
        border-top: 1px solid $black;
        padding-top: 35px;

        .article-content {
          @extend %typography-b1;

          /deep/ {
            /* /deep/ selector is needed to apply styles to markup added through v-html directive */
            p {
              @extend %typography-b1;

              margin-bottom: 28px;
            }

            h2 {
              @extend %typography-h3;

              border-top: 1pt solid $grey-2;
              padding-top: 35px;
              margin-top: 35px;
              margin-bottom: 28px;
            }

            h3 {
              @extend %typography-b1;

              font-weight: 700;
            }

            ol {
              @extend %primary-ordered-list;
            }

            ul {
              @extend %primary-unordered-list;
            }

            ol,
            ul {
              ul {
                @extend %secondary-list;
              }
            }

            @extend %callout-messages;

            img {
              max-width: 100%;
              max-height: 400px;
              height: auto;
              text-align: center;
              margin-bottom: 30px;
            }

            .imageBlock {
              width: 100% !important;
              margin-bottom: 22px;

              img {
                align-self: center;
                margin-bottom: 11px;
              }

              .imageCredit {
                text-align: left;

                @extend %typography-caption;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .article-page {
    padding: 18px 0;

    .page-content .middle-content {
      padding-left: 0;

      .inner-middle-content {
        margin-top: 0;
        border-top: none;
        padding-top: 27px;

        .article-content /deep/ {
          p {
            line-height: 26px;
            margin-bottom: 23px;
          }

          h2 {
            padding-top: 27px;
            margin: 28px 0 21px;
          }
        }
      }
    }

    .article-cat-btngroup {
      flex-wrap: wrap;

      button {
        margin-bottom: 10px;
      }
    }

    .right-sidebar {
      display: flex;
      flex-direction: column;

      .book-purchase-cta {
        order: 1;
        margin-bottom: 32px;
      }

      .ra-box {
        order: 2;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .article-page {
    padding: 8px 0;

    .page-content .middle-content {
      .inner-middle-content {
        .article-content /deep/ {
          p {
            line-height: 24px;
          }

          h2 {
            padding-top: 26px;
            margin-bottom: 22px;
          }
        }
      }
    }
  }
}
</style>
