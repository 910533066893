<template>
  <b-row class="article-intoduction">
    <b-col md="12">
      <breadcrumbs
        :items="articleIntroductionState.breadcrumbs"
        :article-title="articleIntroductionState.title"
      />
      <h1
        class="h1"
        data-testid="intruductionTitle"
      >
        {{ articleIntroductionState.title }}
      </h1>
      <article-author
        :authors="articleIntroductionState.authors"
        :modified-time="articleIntroductionState.modifiedTime"
        :book-title="articleIntroductionState.bookTitle"
        :book-path="articleIntroductionState.bookPath"
      />
      <div
        ref="header"
        class="socials-block"
        :class="{ 'affix': isHeaderSticky }"
        data-testid="introSpyNavigationTest"
      >
        <spy-navigation-for-tablet
          :items="articleIntroductionState.inThisArticle"
          class="spy-nav"
        />
        <share-links :is-header-sticky="isHeaderSticky" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'
import { articleIntroductionState } from '../../store/article/selector'
import { BRow, BCol } from 'bootstrap-vue'
import Breadcrumbs from '../molecules/Breadcrumbs'
import ArticleAuthor from '../molecules/ArticleAuthor'
import ShareLinks from '../atoms/ShareLinks'
import SpyNavigationForTablet from './SpyNavigationForTablet'
export default {
  name: 'ArticleIntroduction',
  components: {
    Breadcrumbs,
    ArticleAuthor,
    ShareLinks,
    SpyNavigationForTablet,
    BRow,
    BCol
  },
  data: function () {
    return {
      scrollY: null,
      headerTop: 0,
      isHeaderSticky: false,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    ...mapState({
      articleIntroductionState: state => articleIntroductionState(state)
    })
  },
  watch: {
    scrollY (newValue) {
      if (newValue > this.headerTop) {
        this.isHeaderSticky = true
      } else {
        this.isHeaderSticky = false
      }
    }
  },
  created () {
    window.addEventListener('load', this.fixToTop)
    window.addEventListener('resize', this.fixToTop)
  },
  destroyed () {
    window.removeEventListener('load', this.fixToTop)
    window.removeEventListener('resize', this.fixToTop)
    window.removeEventListener('scroll', this.scrollTop)
  },
  methods: {
    fixToTop (e) {
      this.windowWidth = window.innerWidth
      if (this.windowWidth < 1023) {
        window.addEventListener('scroll', this.scrollTop)
        this.headerTop = this.$refs.header.getBoundingClientRect().top
      }
    },
    scrollTop () {
      this.scrollY = Math.round(window.scrollY)
    }
  }
}
</script>

<style lang="scss" scoped>
.article-intoduction {
  h1 {
    margin: 25px 0 40px;
  }

  .spy-nav {
    display: none;
  }

  .socials-block {
    display: flex;
  }

  .row {
    margin-right: -20px;
    margin-left: -20px;

    div[class*=col-] {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .article-intoduction {
    h1 {
      margin: 13px 0 32px;
    }

    .spy-nav {
      display: initial;
    }

    .affix {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      padding: 12px 16px;
      box-shadow: 0 1px 2px #00000029;
      background: $white;
      z-index: 1;
    }

    .row {
      margin-right: -16px;
      margin-left: -16px;

      div[class*=col-] {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 16px;
        padding-left: 16px;
      }
    }
  }
}
</style>
