<template>
  <div class="sharelinks">
    <ul
      :class="{ 'fixed-header': isHeaderSticky }"
      data-testid="shareLinkList"
    >
      <li class="fb-icon">
        <a
          href="#"
          data-testid="shareFaceBook"
        >
          <img
            src="../../assets/svg/facebook.svg"
            alt="Facebook"
          >
        </a>
      </li>
      <li class="tw-icon">
        <a
          href="#"
          data-testid="shareTwitter"
        >
          <img
            src="../../assets/svg/twitter.svg"
            alt="Twitter"
          >
        </a>
      </li>
      <li class="ln-icon">
        <a
          href="#"
          data-testid="shareLinkedIn"
        >
          <img
            src="../../assets/svg/linkedIn.svg"
            alt="Twitter"
          >
        </a>
      </li>
      <li class="mail-icon">
        <a
          href="#"
          data-testid="shareEmail"
        >
          <img
            src="../../assets/svg/email.svg"
            alt="Twitter"
          >
        </a>
      </li>
      <li class="print-icon h-mobile d-desktop">
        <a
          href="#"
          data-testid="sharePrint"
        >
          <img
            src="../../assets/svg/print.svg"
            alt="Twitter"
          >
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ShareLinks',
  props: {
    isHeaderSticky: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.sharelinks ul {
  margin: 0;
  padding: 0 0 40px 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 0;

  li {
    margin: 0 16px 0 0;

    a {
      background: $grey-4;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: $hover1;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .sharelinks ul {
    margin-bottom: 32px;
    padding-bottom: 0;

    li {
      margin-right: 8px;

      &.mail-icon {
        margin-right: 0;
      }
    }

    &.fixed-header {
      margin-bottom: 0;
    }
  }

  .print-icon {
    display: none;
  }
}
</style>
