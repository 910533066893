<template>
  <div class="custom-spynav">
    <div class="custom-spynav-desktop">
      <div class="sticky-sidebar">
        <h3 data-testid="spyNavigationHead">
          In This Article
        </h3>

        <b-nav
          v-b-scrollspy
          pills
          class="spynav-listing nav-stacked"
        >
          <b-nav-item href="#tab0">
            Introduction
          </b-nav-item>

          <b-nav-item
            v-for="(item, index) in items"
            :key="`spyNavItem#${index}`"
            :href="item.target"
          >
            {{ item.label }}
          </b-nav-item>
        </b-nav>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BNav,
  BNavItem,
  VBScrollspy
} from 'bootstrap-vue'

export default {
  name: 'SpyNavigation',
  components: {
    BNav,
    BNavItem
  },
  directives: {
    'b-scrollspy': VBScrollspy
  },
  props: {
    items: {
      type: Array,
      required: true,
      validator: function (items) {
        const keys = ['label', 'target']
        return items.every(type => keys.every(key => key in type))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-spynav-desktop {
  position: sticky;
  top: 0;

  .sticky-sidebar {
    max-width: 192px;
    padding: 0;

    h3 {
      font-size: 1.125em;
      line-height: 28px;
      padding: 40px 0;
      font-weight: 700;
      margin-bottom: 0;
    }

    .spynav-listing {
      margin: 0;
      padding: 0;
      border-bottom: 1px solid $grey-4;

      li {
        margin-bottom: 0;
        width: 100%;

        a {
          font-size: 0.875em;
          line-height: 20px;
          color: $black;
          display: block;
          text-decoration: none;
          padding: 24px 0;
          border-top: 1px solid $grey-2;
          border-radius: 0;

          &:hover {
            text-decoration: underline;
          }

          &.active {
            font-weight: 700;
            border-top-color: $black;
            background: transparent;
            color: $black;
          }
        }

        &.activeSelect {
          a {
            font-weight: 700;
            border-top-color: $black;
            color: $black;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    display: none;
  }
}

</style>
