<template>
  <div
    v-if="aboutAuthorState.authorsInfo"
    class="author-section"
  >
    <h2 data-testid="aboutAuthorHead">
      About the Book Author
    </h2>
    <p
      data-testid="aboutAuthorInfo"
      v-html="aboutAuthorState.authorsInfo"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { aboutAuthorState } from '../../store/article/selector'
export default {
  name: 'AboutAuthor',
  computed: {
    ...mapState({
      aboutAuthorState: state => aboutAuthorState(state)
    })
  }
}
</script>

<style lang="scss" scoped>
.author-section {
  margin: 40px 0;

  p {
    @extend %typography-b1;
  }

  h2 {
    @extend %typography-h3;

    border-top: 1pt solid $grey-2;
    padding-top: 35px;
    margin-bottom: 28px;
  }

  @media only screen and (max-width: $breakpoint-md-max) {
    margin: 28px 0;

    h2 {
      margin-bottom: 22px;
      padding-top: 27px;
    }
  }
}
</style>
