<template>
  <article-template />
</template>

<script>
import { mapActions } from 'vuex'
import ArticleTemplate from '../components/templates/ArticleTemplate'
import { AppStateModuleName } from '../store/config'
export default {
  name: 'ArticlePage',
  components: {
    ArticleTemplate
  },
  watch: {
    $route: async function (oldRoute, newRoute) {
      if (oldRoute.params.article !== newRoute.params.article) {
        this.getArticle()
      }
    }
  },
  async created () {
    await this.registerState()
    this.getArticle()
  },
  methods: {
    ...mapActions({
      getArticleAction: 'getArticleAction'
    }),
    async registerState () {
      const articleModule = await import('../store/article')
      if (!this.$store.hasModule(AppStateModuleName.article)) {
        this.$store.registerModule(AppStateModuleName.article, articleModule.default)
      }
    },
    getArticle () {
      const { article } = this.$route.params
      const articleId = article.split('-').pop()

      this.getArticleAction({ articleId })
    }
  }
}
</script>
