<template>
  <div class="author">
    <div
      v-if="authors.length"
      class="author-info"
      data-testid="authorsField"
    >
      <strong>By: </strong>

      <span
        v-for="(author, index) in authors"
        :key="`articleAuthor#${index}`"
        class="author-info-list"
      >
        <router-link
          :to="`/author/${author.slug}-${author.authorId}`"
          data-testid="authorLink"
        >
          {{ author.name }}
        </router-link>

        <span class="list-sep"> and </span>
      </span>
    </div>

    <div
      class="author-info"
      data-testid="modifiedTime"
    >
      <strong>Updated:</strong> {{ modifiedTime }}
    </div>

    <div class="author-info from-book">
      <strong>From The Book: </strong>

      <router-link
        :to="bookPath"
        class="link-underline"
        data-testid="bookLink"
      >
        {{ bookTitle }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleAuthor',
  props: {
    bookTitle: {
      type: String,
      required: true
    },
    bookPath: {
      type: String,
      required: true
    },
    authors: {
      type: Array,
      required: true,
      validator: function (authors) {
        const keys = ['name', 'slug', 'authorId']
        return authors.every(author => keys.every(key => key in author))
      }
    },
    modifiedTime: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.author {
  display: flex;
  margin-bottom: 40px;
  line-height: 16px;

  a {
    color: $black;
    text-decoration: underline;
    font-size: 1rem;

    &:hover {
      color: $grey-3;
    }
  }

  &-info {
    padding-right: 16px;
    margin-right: 16px;
    position: relative;

    &-list {
      &:last-child {
        .list-sep {
          display: none;
        }
      }
    }
  }

  & > div {
    &::before {
      content: '';
      width: 1px;
      height: 16px;
      position: absolute;
      right: 0;
      top: 0;
      background: $black;
    }

    &:last-child::before {
      display: none;
    }
  }

  strong {
    font-weight: 600;
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .author {
    flex-direction: column;
    margin-bottom: 0;
    font-size: 0.875rem;

    a {
      font-size: 0.875rem;
    }

    &-info {
      margin: 0 0 32px 0;
      padding-right: 0;

      &::before {
        display: none;
      }

      &:last-child {
        margin-right: 0;
        padding-right: 0;
        margin-bottom: 0;
      }
    }
  }

  .from-book { display: none; }
}
</style>
