import { dateStringTommddyyyy } from '../../utils/DateTime'
import { ApiStatus } from '../../api/constants/ApiConstants'

export const articleTemplateState = state => {
  const {
    articleState: {
      articleLoadedStatus = ApiStatus.initial,
      article: {
        data: {
          content = '',
          inThisArticle = []
        } = {}
      } = {}
    } = {}
  } = state
  return {
    articleLoadedStatus,
    content,
    inThisArticle
  }
}

export const articleIntroductionState = state => {
  const {
    articleState: {
      article: {
        data: {
          title = '',
          authors = [],
          breadcrumbs = [],
          inThisArticle = [],
          relatedBook: {
            title: bookTitle = '',
            slug = '',
            bookId = '',
            categoryList = []
          } = {}
        } = {},
        headers: {
          modifiedTime = ''
        } = {}
      } = {}
    } = {}
  } = state
  return {
    title,
    bookTitle,
    bookPath: bookId ? `/book/${categoryList.join('/')}/${slug}-${bookId}` : '',
    authors,
    breadcrumbs,
    inThisArticle,
    modifiedTime: dateStringTommddyyyy(modifiedTime)
  }
}

export const aboutAuthorState = state => {
  const {
    articleState: {
      article: {
        data: {
          relatedBook: {
            authorsInfo = []
          } = {}
        } = {}
      } = {}
    } = {}
  } = state
  return {
    authorsInfo
  }
}

export const articleIncludedInState = state => {
  const {
    articleState: {
      article: {
        data: {
          breadcrumbs = []
        } = {}
      } = {}
    } = {}
  } = state
  return {
    breadcrumbs
  }
}

export const relatedArticleListState = state => {
  const {
    articleState: {
      article: {
        data: {
          relatedArticles: {
            fromBook = [],
            fromCategory = []
          } = {},
          trendingArticles = [],
          relatedBook = {
            bookId: null,
            categoryList: [],
            slug: ''
          }
        } = {}
      } = {}
    } = {}
  } = state
  return {
    fromBook,
    trendingArticles,
    fromCategory,
    relatedBook
  }
}

export const bookInfoState = state => {
  const {
    articleState: {
      article: {
        data: {
          relatedBook: {
            title = '',
            amazon: { default: amazonDefaultUrl = '' } = {},
            image: { src: imageUrl = '' } = {},
            bookId,
            slug,
            categoryList = []
          } = {}
        } = {}
      } = {}
    } = {}
  } = state
  return {
    title,
    url: bookId ? `/book/${categoryList.join('/')}/${slug}-${bookId}` : '',
    imageUrl,
    amazonDefaultUrl
  }
}
