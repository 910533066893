<template>
  <div class="article-included">
    <h6 data-testid="articleIncludedTest">
      This Article Is Included In
    </h6>
    <div class="btn-group article-cat-btngroup">
      <custom-button
        v-for="(breadcrumb, index) in articleIncludedInState.breadcrumbs"
        :key="`includedIn#${index}`"
        class="btn"
        tertiary
        :label="breadcrumb.name"
        :to="`/category/articles/${breadcrumb.slugId}`"
        data-testid="categoryButton"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { articleIncludedInState } from '../../store/article/selector'
import CustomButton from '../atoms/CustomButton'
export default {
  name: 'ArticleIncludedIn',
  components: {
    CustomButton
  },
  computed: {
    ...mapState({
      articleIncludedInState: state => articleIncludedInState(state)
    })
  }
}
</script>

<style lang="scss" scoped>
.article-included {
  border-top: 1px solid $black;
  padding: 34px 0 54px;

  h6 {
    @extend %typography-h3;

    margin-bottom: 35px;
    font-weight: 800;
  }

  .article-cat-btngroup {
    flex-wrap: wrap;

    .btn {
      margin: 0 10px 10px 0;
    }
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .article-included {
    border-bottom: 1px solid $black;
    padding: 27px 0 22px;
    margin-bottom: 31px;

    h6 {
      margin-bottom: 27px;
    }
  }
}
</style>
