<template>
  <div class="custom-spynav">
    <div class="custom-spynav-tablet">
      <button
        v-b-modal.modal-spynav
        class="ita-btn"
        data-testid="spyNavigationTabletButton"
      >
        In This Article
      </button>
      <b-modal
        id="modal-spynav"
        ref="my-modal"
        title="In This Article"
        dialog-class="fullwidth-modal"
        hide-footer
        data-testid="spyNavigationTabletModal"
      >
        <div class="sticky-sidebar">
          <b-nav
            v-b-scrollspy
            pills
            class="spynav-listing nav-stacked"
          >
            <b-nav-item
              href="#tab0"
            >
              Introduction
            </b-nav-item>
            <b-nav-item
              v-for="(item, index) in items"
              :key="`spyNavTabletItem#${index}`"
              :href="item.target"
              @click="hideModal"
            >
              {{ item.label }}
            </b-nav-item>
          </b-nav>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BNav,
  BNavItem,
  BModal,
  VBModal,
  VBScrollspy

} from 'bootstrap-vue'
export default {
  name: 'SpyNavigationForTablet',
  components: {
    BNav,
    BNavItem,
    BModal
  },
  directives: {
    'b-modal': VBModal,
    'b-scrollspy': VBScrollspy
  },
  props: {
    items: {
      type: Array,
      required: true,
      validator: function (items) {
        const keys = ['label', 'target']
        return items.every(type => keys.every(key => key in type))
      }
    }
  },
  methods: {
    hideModal () {
      this.$refs['my-modal'].hide()
    }
  }
}
</script>

<style lang="scss">
.modal-dialog.fullwidth-modal {
  max-width: none;
  margin: 0;

  .modal-content {
    border: none;
    width: 100%;
    height: 100vh;
    border-radius: 0;
    padding-top: 97px;

    .modal-header {
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-bottom: none;
      position: fixed;
      top: 0;
      width: 100%;
      height: 96px;
      background: $white;
      z-index: 9;

      .modal-title {
        font-size: 0.875em;
        font-weight: 700;
      }

      button.close {
        width: 24px;
        height: 24px;
        background: $black;
        border-radius: 50%;
        opacity: 1;
        color: $white;
        font-weight: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 1.125rem;
        margin-right: 0;
      }
    }

    .modal-body {
      padding: 0 16px;
      height: 100vh;
      overflow-y: auto;

      .sticky-sidebar {
        width: 100%;
        position: relative;
        max-width: none;

        .spynav-listing {
          position: relative;
          top: 0;
          border-bottom: 1px solid $grey-4;

          li {
            padding: 0;
            width: 100%;
            margin-bottom: 0;

            a {
              font-size: 0.875em;
              line-height: 20px;
              color: $black;
              display: block;
              text-decoration: none;
              padding: 24px 0;
              border-top: 1px solid $grey-2;
              border-radius: 0;

              &:hover {
                text-decoration: underline;
              }

              &.active {
                font-weight: 700;
                border-top-color: $black;
                background: transparent;
                color: $black;
              }
            }
          }
        }
      }
    }
  }
}

.custom-spynav-tablet .ita-btn {
  @extend %ita-btn;
}

@media only screen and (max-width: 1023px) {
  .article-page .custom-spynav-tablet {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .article-page .custom-spynav-tablet {
    display: none;
  }
}
</style>
